<template>
  <div id="firebaseui-auth-container" />
</template>

<script>
import { firebase } from '@/services/firebase'
import { adminGetValidAdmin } from '@/services/functions'
import { mapActions } from 'vuex'

export default {
  metaInfo () {
    return {
      script: [
        {
          callback: () => {
            window.firebase = firebase
            this.ui = new window.firebaseui.auth.AuthUI(firebase.auth())
            const uiConfig = {
              callbacks: {
                signInSuccessWithAuthResult: (authResult) => {
                  // console.log(JSON.stringify(authResult, null, 2))
                  this.login(authResult.user).then(async () => {
                    let { uid, displayName: name, photoURL: photo, email } = authResult.user
                    const { email: otherEmail } = authResult.user.providerData[0] // Assume only 1 providerData
                    email = email === otherEmail ? email : otherEmail
                    const { data } = await adminGetValidAdmin(email)
                    const company = data ? data.company : null
                    this.success({ company, uid, name, photo, email })
                  })
                  return false
                }
              },
              // signInWithRedirect: is not working with localhost anymore --> use signInWithPopup on localhost
              ...(window.location.hostname === 'localhost' ? { signInFlow: 'popup' } : {}),
              signInOptions: [
                {
                  provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                  customParameters: {
                    prompt: 'select_account'
                  }
                }
              ],
              credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
              tosUrl: 'https://legal.truffle.ai/',
              privacyPolicyUrl: 'https://legal.truffle.ai/privacy-policy/'
            }
            this.ui.start('#firebaseui-auth-container', uiConfig)
          },
          src: this.scriptSource,
          body: true
        }
      ],
      link: [
        {
          type: 'text/css',
          rel: 'stylesheet',
          href: 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth.css'
        }
      ]
    }
  },
  name: 'sign-in',
  data: () => ({
    ui: null
  }),
  methods: {
    ...mapActions('auth', ['login']),
    success (data) {
      this.$emit('success', data)
    }
  },
  computed: {
    scriptSource () {
      const browserLocale = navigator.language.split('-')[0]
      return browserLocale === 'ja'
        ? 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth__ja.js'
        : 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth__en.js'
    }
  },
  destroyed () {
    this.ui.delete()
  }
}
</script>

<style>
/* Collapse height of firebase ui loading spinner container */
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card.firebaseui-container {
  min-height: 2px;
}

#firebaseui-auth-container ul.firebaseui-idp-list {
  padding-left: 0;
}
</style>
